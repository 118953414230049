import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry, provideGlobalGridOptions } from '@ag-grid-community/core';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { AG_GRID_LOCALE_CN } from '@ag-grid-community/locale';

provideGlobalGridOptions(
  {
    theme: {
      startUse() {},
      stopUse() {},
      getCssClass() {
        return 'ag-theme-quartz';
      },
    },
    localeText: AG_GRID_LOCALE_CN,
    pagination: false,
    paginationAutoPageSize: false,
    paginationPageSize: 50,
    paginationPageSizeSelector: [10, 20, 50, 100, 500, 1000],
    rowHeight: 40,
    defaultColDef: {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: false,
      editable: false,
      resizable: true,
    },
  },
  'deep',
);
ModuleRegistry.registerModules([ClientSideRowModelModule]);
