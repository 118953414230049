import type { ServiceOrderDetailProjectVO } from '@/pages/anchorDetail/types.ts';
import type { ColDef } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { observer } from 'mobx-react';
import { useState } from 'react';

const SectionReport = observer(() => {
  const [rowData, setRowData] = useState([
    { make: 'Tesla', model: 'Model Y', price: 64950, electric: true },
    { make: 'Ford', model: 'F-Series', price: 33850, electric: false },
    { make: 'Toyota', model: 'Corolla', price: 29600, electric: false },
  ]);

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs, setColDefs] = useState<ColDef<any>[]>([
    {
      field: 'projectName',
      headerName: '服务名称',
    },
  ]);
  return (
    <div className="w-full h-full">
      1234
      <AgGridReact
        rowData={rowData}
        columnDefs={colDefs}
      />
    </div>
  );
});

export default SectionReport;
